<template>
  <div>
    <app-collapse
      v-for="(trip, tripIndex) in selectedTrips"
      :key="tripIndex"
      class="mb-50 mb-md-1 border-info-2 rounded-lg"
    >
      <app-collapse-item
        title
        :card-class="'rounded-lg'"
        :class-header="'justify-content-center py-0'"
        :header-bg-variant="'light-warning'"
        :class-body="'px-75 pb-75'"
      >
        <!-- Header -->
        <template #header>
          <div
            :class="`w-100 d-flex flex-wrap align-items-center justify-content-around ${isMobileView ? 'font-medium-1' : 'font-medium-4'}`"
            style="max-width: 1200px;"
          >
            <div class="d-flex-center">
              <!-- ANCHOR - Logo -->
              <div class="mr-50">
                <IAmLogoAirline
                  :airline="trip.segments[0].operating === 'BL' ? trip.segments[0].operating : (trip.airline.split(' | ')[0] || trip.operator)"
                  :custom-width="isMobileView ? '40' : '60'"
                />
              </div>
              <!-- ANCHOR - Hãng -->
              <div>
                <span class="fw-700 text-body">
                  {{ trip.segments.map((seg) => `${resolveAirlineFlightNumber(seg.airline, seg.flightNumber)}`).join(', ') }}
                </span>
              </div>
            </div>
            <div class="fw-700 text-body">
              <span>{{ convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone ).time }}</span>
              -
              <span>{{ convertISODateTime(trip.arrival.at, trip.arrival.timezone || trip.arrival.timeZone ).time }}</span>
            </div>
            <div class="fw-700 text-body">
              <span>{{ trip.segments.map(s => `${s.departure.IATACode}-${s.arrival.IATACode}`).join('-').split('-').filter((item, index, arr) => item !== arr[index + 1]).join('-') }}</span>
            </div>
            <div class="fw-700 text-body">
              <span>
                {{ isShowYearOfFlightDateInBookingTripDetail
                  ? convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone ).date
                  : convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone ).dayAndMonth }}
              </span>
            </div>
            <!-- <div class="fw-700 text-body">
              <span>
                {{ trip.segments.map(s => convertShortenAircraft(s.airCraft)).filter(Boolean).join(', ') }}
              </span>
            </div> -->
          </div>
        </template>

        <div
          v-for="(segment, indexSegment) in trip.segments"
          :key="indexSegment"
        >
          <div v-if="segment.flightLegInfoList && segment.flightLegInfoList.length > 0">
            <FlightDetail
              v-for="(seg, indexSeg) in segment.flightLegInfoList"
              :key="indexSeg"
              :class="indexSeg !== 0 ? 'border-top' : ''"
              :segment="seg"
              :trip="trip"
              :index="tripIndex"
              :index-segment="indexSegment"
              :operating="segment.operating"
              :airline="segment.airline"
              :flight-number="segment.flightNumber"
              :selected-trip="trip"
            />
          </div>
          <FlightDetail
            v-else
            :segment="segment"
            :trip="trip"
            :index="tripIndex"
            :index-segment="indexSegment"
            :operating="segment.operating"
            :airline="segment.airline"
            :flight-number="segment.flightNumber"
            :selected-trip="trip"
          />
          <!-- SECTION Giữa chặng -->
          <div v-if="indexSegment !== trip.segments.length - 1">
            <div v-if="segment.isNextAirport">
              <b-alert
                show
                variant="warning"
                class="py-50 px-1 shadow-sm d-flex-center"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="21"
                  class="text-warning mr-1"
                />
                <b>{{ $t('flight.alertNextAirport') }}</b>
              </b-alert>
            </div>
            <div v-if="segment.isNextDay">
              <b-alert
                show
                variant="warning"
                class="py-50 px-1 shadow-sm d-flex-center"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="21"
                  class="text-warning mr-1"
                />
                <b>{{ $t('flight.alertNextDay') }}</b>
              </b-alert>
            </div>
            <div v-if="segment.transitTime">
              <b-alert
                show
                variant="primary"
                class="py-50 px-1 shadow-sm d-flex-center"
              >
                <feather-icon
                  icon="InfoIcon"
                  size="21"
                  class="text-primary mr-1"
                />
                <b>{{ $t('ticket.flightsInfo.transitTime') }}: {{ segment.transitTime }}</b>
              </b-alert>
            </div>
            <b-alert
              show
              variant="primary"
              class="py-50 px-1 shadow-sm d-flex-center"
            >
              <feather-icon
                icon="InfoIcon"
                size="21"
                class="text-primary mr-1"
              />
              <b>{{ $t('flight.transitFlightInfo') }}</b>
            </b-alert>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BAlert, VBTooltip, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import FlightDetail from '@/components/flight-detail/FlightDetail.vue'
import env from '@/libs/env'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  convertISODateTime, toHoursAndMinutes, formatCurrency, resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BAlert,
    AppCollapse,
    AppCollapseItem,
    FlightDetail,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    isCreateGroupBooking: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    getAirlineNameByCode(code) {
      return this.$store.getters['globalConfig/getAirlineNameByCode'](code)
    },
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    fareRulesAirline(airline, cabin) {
      return this.$store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
    },
  },
  setup() {
    const {
      getAirportByAirportCode,
      convertShortenAircraft,
    } = useBookingHandle()
    const optionStop = [
      { text: 'direct', value: 0 },
      { text: '1_transits', value: 1 },
      { text: '2_transits', value: 2 },
    ]
    return {
      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      getAirportByAirportCode,
      convertShortenAircraft,
      optionStop,
      resolveAirlineFlightNumber,
      isShowYearOfFlightDateInBookingTripDetail: env.isShowYearOfFlightDateInBookingTripDetail,
    }
  },
}
</script>

<style lang="scss" scoped>
.hr_dashed {
  border: 1px dashed #999999;
  padding: 0 20px;
}
</style>
