var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.selectedTrips, function (trip, tripIndex) {
    return _c('app-collapse', {
      key: tripIndex,
      staticClass: "mb-50 mb-md-1 border-info-2 rounded-lg"
    }, [_c('app-collapse-item', {
      attrs: {
        "title": "",
        "card-class": 'rounded-lg',
        "class-header": 'justify-content-center py-0',
        "header-bg-variant": 'light-warning',
        "class-body": 'px-75 pb-75'
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            class: "w-100 d-flex flex-wrap align-items-center justify-content-around ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-4'),
            staticStyle: {
              "max-width": "1200px"
            }
          }, [_c('div', {
            staticClass: "d-flex-center"
          }, [_c('div', {
            staticClass: "mr-50"
          }, [_c('IAmLogoAirline', {
            attrs: {
              "airline": trip.segments[0].operating === 'BL' ? trip.segments[0].operating : trip.airline.split(' | ')[0] || trip.operator,
              "custom-width": _vm.isMobileView ? '40' : '60'
            }
          })], 1), _c('div', [_c('span', {
            staticClass: "fw-700 text-body"
          }, [_vm._v(" " + _vm._s(trip.segments.map(function (seg) {
            return "".concat(_vm.resolveAirlineFlightNumber(seg.airline, seg.flightNumber));
          }).join(', ')) + " ")])])]), _c('div', {
            staticClass: "fw-700 text-body"
          }, [_c('span', [_vm._v(_vm._s(_vm.convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone).time))]), _vm._v(" - "), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(trip.arrival.at, trip.arrival.timezone || trip.arrival.timeZone).time))])]), _c('div', {
            staticClass: "fw-700 text-body"
          }, [_c('span', [_vm._v(_vm._s(trip.segments.map(function (s) {
            return "".concat(s.departure.IATACode, "-").concat(s.arrival.IATACode);
          }).join('-').split('-').filter(function (item, index, arr) {
            return item !== arr[index + 1];
          }).join('-')))])]), _c('div', {
            staticClass: "fw-700 text-body"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.isShowYearOfFlightDateInBookingTripDetail ? _vm.convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone).date : _vm.convertISODateTime(trip.departure.at, trip.departure.timezone || trip.departure.timeZone).dayAndMonth) + " ")])])])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(trip.segments, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [segment.flightLegInfoList && segment.flightLegInfoList.length > 0 ? _c('div', _vm._l(segment.flightLegInfoList, function (seg, indexSeg) {
        return _c('FlightDetail', {
          key: indexSeg,
          class: indexSeg !== 0 ? 'border-top' : '',
          attrs: {
            "segment": seg,
            "trip": trip,
            "index": tripIndex,
            "index-segment": indexSegment,
            "operating": segment.operating,
            "airline": segment.airline,
            "flight-number": segment.flightNumber,
            "selected-trip": trip
          }
        });
      }), 1) : _c('FlightDetail', {
        attrs: {
          "segment": segment,
          "trip": trip,
          "index": tripIndex,
          "index-segment": indexSegment,
          "operating": segment.operating,
          "airline": segment.airline,
          "flight-number": segment.flightNumber,
          "selected-trip": trip
        }
      }), indexSegment !== trip.segments.length - 1 ? _c('div', [segment.isNextAirport ? _c('div', [_c('b-alert', {
        staticClass: "py-50 px-1 shadow-sm d-flex-center",
        attrs: {
          "show": "",
          "variant": "warning"
        }
      }, [_c('feather-icon', {
        staticClass: "text-warning mr-1",
        attrs: {
          "icon": "AlertTriangleIcon",
          "size": "21"
        }
      }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextAirport')))])], 1)], 1) : _vm._e(), segment.isNextDay ? _c('div', [_c('b-alert', {
        staticClass: "py-50 px-1 shadow-sm d-flex-center",
        attrs: {
          "show": "",
          "variant": "warning"
        }
      }, [_c('feather-icon', {
        staticClass: "text-warning mr-1",
        attrs: {
          "icon": "AlertTriangleIcon",
          "size": "21"
        }
      }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextDay')))])], 1)], 1) : _vm._e(), segment.transitTime ? _c('div', [_c('b-alert', {
        staticClass: "py-50 px-1 shadow-sm d-flex-center",
        attrs: {
          "show": "",
          "variant": "primary"
        }
      }, [_c('feather-icon', {
        staticClass: "text-primary mr-1",
        attrs: {
          "icon": "InfoIcon",
          "size": "21"
        }
      }), _c('b', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.transitTime')) + ": " + _vm._s(segment.transitTime))])], 1)], 1) : _vm._e(), _c('b-alert', {
        staticClass: "py-50 px-1 shadow-sm d-flex-center",
        attrs: {
          "show": "",
          "variant": "primary"
        }
      }, [_c('feather-icon', {
        staticClass: "text-primary mr-1",
        attrs: {
          "icon": "InfoIcon",
          "size": "21"
        }
      }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.transitFlightInfo')))])], 1)], 1) : _vm._e()], 1);
    }), 0)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }